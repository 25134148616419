import useJwt from '@/api/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const clearUserData = () => { useJwt.clearUserData() }

export const getSiteRoutes = () => JSON.parse(localStorage.getItem('siteRoutes'))

export const isAllCustomerUser = () => JSON.parse(localStorage.getItem('userData')).claims.includes('customer:all')

export const allowAssetManagement = () => JSON.parse(localStorage.getItem('userData')).scopes.includes('asset:write')

export const allowDeviceManagement = () => JSON.parse(localStorage.getItem('userData')).scopes.includes('device:update')

export const allowFactoryManagement = () => JSON.parse(localStorage.getItem('userData')).scopes.includes('factory:write')

export const allowHistoryView = () => JSON.parse(localStorage.getItem('userData')).scopes.includes('asset:history:read')

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole !== '' || userRole !== undefined) return '/'
  return { name: 'auth-login' }
}
