import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import {
  isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, getSiteRoutes, clearUserData,
} from '@/api/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

let newRoutes = []
const siteRoutes = getSiteRoutes()

try {
  if (siteRoutes) {
    newRoutes = siteRoutes.map(route => {
      // eslint-disable-next-line import/no-self-import, no-param-reassign
      route.component = () => import('@/views/dashboard/factory/Analytics.vue')
      return route
    })
    newRoutes.push({ path: '/', redirect: newRoutes[0] })
  } else {
    newRoutes.push({ path: '/', redirect: { name: 'dashboard-factory' } })
    clearUserData()
  }
} catch (error) {
  newRoutes.push({ path: '/', redirect: { name: 'dashboard-factory' } })
  console.log('error', error)
  clearUserData()
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },

  routes: [
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...newRoutes,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
