import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllFactory() {
      return new Promise((resolve, reject) => {
        axios
          .get('/customer/factory/all')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllLines() {
      return new Promise((resolve, reject) => {
        axios
          .get('/customer/factory/line')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllSites() {
      return new Promise((resolve, reject) => {
        axios
          .get('/customer/factory/site')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllBuildings() {
      return new Promise((resolve, reject) => {
        axios
          .get('/customer/factory/building')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFactory(ctx, factory) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/customer/factory/${factory.id}`, factory)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFactory(ctx, factory) {
      return new Promise((resolve, reject) => {
        axios
          .put('/customer/factory', factory)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFactory(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/customer/factory/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFaultCodes() {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/faultcodes')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFactorySite(ctx, factory) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/customer/factorysite/${factory.id}`, factory)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
