import Vue from 'vue'
import axios from 'axios'
import apiconfig from './apiconfig'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: apiconfig.ApiUrl,
  timeout: 30000,
  headers: {
    'Ocp-Apim-Subscription-Key': apiconfig.azureAPIKey,
    'Content-Type': 'application/json',
  },
})

axiosIns.defaults.baseURL = apiconfig.ApiUrl
axiosIns.defaults.azureAPIKey = apiconfig.azureAPIKey

Vue.prototype.$http = axiosIns

export default axiosIns
