import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA7bgppEqxLi9VdpwwzCyfDHBvFVHvExzk',
    libraries: 'places',
    installComponents: true,
  },
  installComponents: true,
})
Vue.component(FeatherIcon.name, FeatherIcon)
